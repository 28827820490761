import '../styles/Card.css';

function Card({ props }) {
  console.log(props);
  const { top, mid, bot, button } = props;
  return (
    <div className="Card">
      <div className="Top">{top}</div>
      <div className="Mid">{mid}</div>
      <div className="Bot">{bot}</div>
      {button ? button : <></>}
    </div>
  );
}

export default Card;
