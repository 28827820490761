// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import * as serviceWorker from "./serviceWorker";
import './index.css';
// import firebase from "./components/Firebase";
import { app, db, analytics, auth } from './components/Firebase';

import Login from './components/Login';
import Admin from './components/Admin';
import Header from './components/Header';

function App() {
  const [loginStatus, setLoginStatus] = useState('loading');
  const [uid, setUid] = useState('');
  const [user, setUser] = useState();
  useEffect(() => {
    auth.onAuthStateChanged(
      function (user) {
        if (user) {
          user.getIdToken().then(function (accessToken) {
            setLoginStatus('logged in');
            setUid(user.uid);
            setUser(user);
          });
        } else {
          setLoginStatus('logged out');
        }
      },
      function (error) {
        console.log(error);
      }
    );
  }, []);

  console.log(loginStatus);

  if (loginStatus === 'logged in') {
    return (
      <BrowserRouter>
        <div className="App">
          <Header />
          <Routes>
            {/* <Route path="/register" element={Register} />
            <Route path="/admin" element={Admin} />
            <Route path="/" element={Orders} /> */}
            <Route path="/" element={<Admin uid={uid} user={user} />} />
          </Routes>
        </div>
      </BrowserRouter>
    );
  } else if (loginStatus === 'logged out') {
    return <Login />;
  } else {
    return <h1 style={{ textAlign: 'center', marginTop: '100px' }}>Loading...</h1>;
  }
}

// ReactDOM.render(<App />, document.getElementById("root"));
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// serviceWorker.unregister();
