import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDD15-EcroZ8XyCyGVYuKolOZItMhJ478A',
  authDomain: 'meetsummary-55b44.firebaseapp.com',
  projectId: 'meetsummary-55b44',
  storageBucket: 'meetsummary-55b44.appspot.com',
  messagingSenderId: '929088932173',
  appId: '1:929088932173:web:8d7d7378d2f6fe1ccd8f04',
  measurementId: 'G-79T66DKN3K'
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);

// export default app;
