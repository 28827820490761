import React, { useState, useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { db } from './Firebase';
import { collection, doc, setDoc, getDoc, addDoc } from 'firebase/firestore';
import Card from './Card';
import '../styles/Admin.css';
// import ApiCalendar from 'react-google-calendar-api';

// const config = {
//   clientId: '929088932173-6vfe2dmpo86a4idb752didj7b3plk22l.apps.googleusercontent.com',
//   apiKey: 'AIzaSyDD15-EcroZ8XyCyGVYuKolOZItMhJ478A',
//   scope: 'https://www.googleapis.com/auth/calendar',
//   discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest']
// };

// const apiCalendar = new ApiCalendar(config);

// const handleItemClick = async (event, name) => {
//   if (name === 'sign-in') {
//     await apiCalendar.handleAuthClick();
//     apiCalendar.listUpcomingEvents(10).then(({ result }) => {
//       console.log(result.items);
//     });
//   } else if (name === 'sign-out') {
//     apiCalendar.handleSignoutClick();
//   }
// };

const auth = getAuth();

const createFirestoreObjectIfMissing = async (user, setUserData) => {
  try {
    const docRef = doc(db, 'users', user.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log('Existing user:', docSnap.data());
      setUserData(docSnap.data());
    } else {
      console.log('New user');
      const usersRef = collection(db, 'users');
      const newUserData = {
        uid: user.uid,
        name: user.displayName,
        authProvider: 'google',
        email: user.email,
        freeMeetingsLeft: 3,
        meetingsHad: 0,
        connectedToSlack: false,
        userCreated: new Date()
      };
      setUserData(newUserData);
      await setDoc(doc(usersRef, user.uid), newUserData);
    }
    // }
  } catch (err) {
    console.error(err);
  }
};

function freeMeetingsLeft(freeMeetingsLeft) {
  return freeMeetingsLeft
    ? { top: 'You have', mid: freeMeetingsLeft + ' free meetings left', bot: 'of free summaries' }
    : {
        top: 'You have',
        mid: 'no free meetings left',
        bot: 'of free summaries. Contact sam@meetsummary.com to sing up for a plan'
      };
}

// function MeetingsHad({ meetingsHad }) {
//   if (meetingsHad !== undefined) {
//     return meetingsHad ? (
//       <h3>You have had {meetingsHad} meeting with MeetSummary</h3>
//     ) : (
//       <h3>
//         You have had 0 meetings with MeetSummary so far. Invite bot@meetsummary.com to your meetings
//         to start geting great meeting summaries
//       </h3>
//     );
//   }
// }

function meetingsHad(meetingsHad) {
  return meetingsHad
    ? {
        top: 'You have had',
        mid: meetingsHad + ' meeting summaries',
        bot: 'with MeetSummary. Keep it going!'
      }
    : {
        top: 'You have had',
        mid: 'no meetings summaries',
        bot: 'with MeetSummary so far'
      };
}

// function connectedToSlack(connectedToSlack) {
//   return connectedToSlack
//     ? {
//         top: 'You have',
//         mid: 'connected to Slack',
//         bot: 'Now should be able to get meetings summaries there soon after each meeting with MeetSummary bot'
//       }
//     : {
//         top: 'You have',
//         mid: 'not connected to Slack',
//         bot: 'Connect to Slack to directly get you summaries from Slack bot in addition to email summaries',
//         button: (
//           <div className="SlackButton">
//             <a href="https://slack.com/oauth/v2/authorize?client_id=4730578395926.4722648276215&scope=incoming-webhook&user_scope=">
//               <img
//                 alt="Add to Slack"
//                 height="40"
//                 width="139"
//                 src="https://platform.slack-edge.com/img/add_to_slack.png"
//                 srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
//               />
//             </a>
//           </div>
//         )
//       };
// }

function Admin({ uid, user }) {
  console.log(user);

  const [userData, setUserData] = useState({});

  useEffect(() => {
    createFirestoreObjectIfMissing(user, setUserData);
  }, []);

  return (
    <div className="Admin">
      <div className="AdminName">
        <img src={user.photoURL} />
        <p>{user.displayName}</p>
      </div>
      <Card
        props={{
          top: 'To get started',
          mid: 'invite bot@meetsummary.com',
          bot: 'to your calendar meetings. MeetSummary bot will automatically join your Zoom or Google Meet call and generate summary from it.'
        }}
      />
      {userData.freeMeetingsLeft !== undefined ? (
        <Card props={freeMeetingsLeft(userData.freeMeetingsLeft)} />
      ) : (
        <></>
      )}
      {userData.meetingsHad !== undefined ? (
        <Card props={meetingsHad(userData.meetingsHad)} />
      ) : (
        <></>
      )}
      <Card
        props={{
          top: 'For any questions',
          mid: 'contact us',
          bot: 'at sam@meetsummary.com for anything about the product or feedback'
        }}
      />
      {/* {userData.connectedToSlack !== undefined ? (
        <Card props={connectedToSlack(userData.connectedToSlack)} />
      ) : (
        <></>
      )} */}
      {/* <HoursLeft freeHoursLeft={userData.freeHoursLeft} /> */}
      {/* <hr></hr>
      <MeetingsHad meetingsHad={userData.meetingsHad} /> */}
      {/* <hr></hr> */}
      {/* <SlackConnection connectedToSlack={userData.connectedToSlack} /> */}
      {/* <button onClick={(e) => handleItemClick(e, 'sign-in')}>sign-in</button>
      <button onClick={(e) => handleItemClick(e, 'sign-out')}>sign-out</button> */}
    </div>
  );
}

export default Admin;
